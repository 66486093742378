import { BasePlugin, PluginOptions, Uppy } from '@uppy/core'
import type { UppyFile } from '@uppy/core'
import { Blob, DirectUpload, DirectUploadDelegate } from '@rails/activestorage'

interface ActiveStorageOptions extends PluginOptions {
  directUploadUrl: string
}

export class ActiveStorageUploadPlugin extends BasePlugin {
  declare directUploadUrl: string

  constructor(uppy: Uppy, opts?: ActiveStorageOptions) {
    super(uppy, opts)
    this.type = 'uploader'
    this.id = 'ActiveStorageUploadPlugin'
    this.directUploadUrl = opts?.directUploadUrl ?? ''
    this.handleUpload = this.handleUpload.bind(this)
  }

  install() {
    this.uppy.addUploader(this.handleUpload)
  }

  uninstall() {
    this.uppy.removeUploader(this.handleUpload)
  }

  async handleUpload(fileIDs: string[]) {
    if (fileIDs.length === 0) {
      this.uppy.log('[XHRUpload] No files to upload!')
      return
    }

    const file = this.uppy.getFile(fileIDs[0])
    this.uppy.emit('upload-start', [file])
    await this.uploadFile(file)
  }

  uploadFile(file: UppyFile) {
    return new Promise((resolve, reject) => {
      const directUploadDidProgress = (event: ProgressEvent) => {
        if (event.lengthComputable) {
          this.uppy.emit('upload-progress', file, {
            uploader: this,
            bytesUploaded: event.loaded,
            bytesTotal: event.total,
          })
        }
      }

      const directHandler: DirectUploadDelegate = {
        directUploadWillStoreFileWithXHR: (request: XMLHttpRequest) => {
          request.upload.addEventListener('progress', (event) =>
            directUploadDidProgress(event)
          )
        },
      }

      const upload = new DirectUpload(
        // @ts-expect-error file.data is a File
        file.data,
        this.directUploadUrl,
        directHandler
      )

      upload.create((error: Error, blob: Blob) => {
        this.uppy.emit('upload-started', file)

        if (error) {
          this.uppy.emit('upload-error', file, error)
          return reject(error)
        } else {
          const response = {
            status: 200,
            body: {
              ...blob,
            },
          }
          this.uppy.emit('upload-success', file, response)
          return resolve(file)
        }
      })
    })
  }
}
