import { Controller } from '@hotwired/stimulus'
import type { Stripe } from '@stripe/stripe-js'
import type {
  StripeCardElement,
  StripeElementChangeEvent,
} from '@stripe/stripe-js'

export default class extends Controller {
  static targets = ['card', 'errors', 'form']

  declare cardTarget: HTMLElement
  declare readonly errorsTarget: HTMLElement
  declare readonly formTarget: HTMLFormElement

  declare readonly hasErrorsTarget: boolean

  private stripe: Stripe | undefined | null
  private cardElement?: StripeCardElement

  declare loadStripe: (publishableKey: string) => Promise<Stripe | null>

  async connect() {
    await import('@stripe/stripe-js').then(
      (module) => (this.loadStripe = module.loadStripe)
    )

    if (!this.loadStripe) {
      throw new Error('Stripe module not loaded')
    }

    if (!this.stripePublicKey) {
      throw new Error('Stripe Public Key not found')
    }

    this.stripe = await this.loadStripe(this.stripePublicKey)

    if (!this.stripe) {
      throw new Error('Stripe failed to load')
    }

    const elements = this.stripe.elements()
    this.cardElement = elements.create('card', {
      style: {
        base: {
          fontSize: '16px',
          lineHeight: '24px',
        },
        invalid: {
          color: '#E76978',
        },
      },
    })
    this.cardElement.mount(this.cardTarget)

    this.cardElement.on('change', this.displayErrors)
  }

  disconnect() {
    this.cardElement?.destroy()
  }

  displayErrors = (event: StripeElementChangeEvent) => {
    this.errorsTarget.textContent = event.error?.message ?? ''
  }

  sendToken = (event: SubmitEvent) => {
    event.preventDefault()

    if (
      this.stripe &&
      this.cardElement &&
      this.hasErrorsTarget &&
      this.errorsTarget
    ) {
      this.stripe.createToken(this.cardElement).then((result) => {
        if (result.error) {
          this.errorsTarget.textContent = result.error.message ?? null
        } else {
          this.#stripeTokenHandler(result.token.id)
        }
      })
    }
  }

  #stripeTokenHandler(token: string) {
    const hiddenInput = document.createElement('input')

    hiddenInput.setAttribute('type', 'hidden')
    hiddenInput.setAttribute('name', 'stripeToken')
    hiddenInput.setAttribute('value', token)
    this.formTarget.appendChild(hiddenInput)

    this.formTarget.submit()
  }

  get stripePublicKey() {
    return document
      ?.querySelector('meta[name="current-stripe"]')
      ?.getAttribute('content')
  }
}
