import { Controller } from '@hotwired/stimulus'

type FormFillAttributes = {
  [key: string]: string
}

// Connects to data-controller="form-fill"
export default class extends Controller<HTMLElement> {
  fill(event: Event & { params: { attributes: FormFillAttributes } }): void {
    event.preventDefault()

    const attributes = event.params.attributes

    Object.keys(attributes).forEach((key) => {
      const field = this.element.querySelector(
        `input[data-form-fill-field="${key}"]`
      ) as HTMLInputElement | null

      if (field) field.value = attributes[key]
    })
  }
}
