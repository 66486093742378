import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="alert"
export default class extends Controller<HTMLElement> {
  static values = {
    message: String,
  }

  declare messageValue: string

  show(event: Event): void {
    event.preventDefault()

    alert(this.messageValue)
  }
}
