import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="audio-playback-rate"
export default class extends Controller<HTMLElement> {
  static targets: string[] = ['audioPlayer', 'button']

  declare readonly audioPlayerTarget: HTMLAudioElement
  declare readonly buttonTargets: Array<HTMLButtonElement>

  change(event: Event & { params: { value: number } }): void {
    event.preventDefault()

    const rate = event.params.value

    this.audioPlayerTarget.playbackRate = rate

    this.buttonTargets.forEach((button) => {
      const buttonRate = button.dataset.audioPlaybackRateValueParam ?? '0'
      const active = parseFloat(buttonRate) === rate

      button.setAttribute('aria-current', active.toString())
    })
  }
}
