import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="remove-element"
export default class extends Controller<HTMLElement> {
  remove(e: Event): void {
    e.preventDefault()

    this.element.remove()
  }
}
