import { Controller } from '@hotwired/stimulus'

const MESSAGE =
  'You have unsaved changes that will be lost if you leave the page.'

// Connects to data-controller="unsaved-changes"
export default class extends Controller<HTMLFormElement> {
  static values = {
    hasChanges: { type: Boolean, default: false },
  }

  declare hasChangesValue: boolean

  changed() {
    this.hasChangesValue = true
  }

  submitting() {
    this.hasChangesValue = false
  }

  handleLeave(event: Event) {
    if (!this.hasChangesValue) return

    // For the beforeunload event, you need to simply preventDefault(). When you do that, the
    // browser will automatically display a "Are you sure you want to leave the page?" message.
    // These are not allowed to call `window.confirm()`. It'll raise an error if you try to call
    // confirm() while unloading. One way to trigger a beforeunload is to just reload the page.
    //
    // For a Turbo navigation, you need to display your own confirmation alert and if they Cancel,
    // then preventDefault() to stop the navigation.
    if (event.type !== 'turbo:before-visit' || !window.confirm(MESSAGE)) {
      event.preventDefault()
    }
  }
}
