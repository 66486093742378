import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="alert"
export default class extends Controller<HTMLFormElement> {
  submit(event: Event): void {
    event.preventDefault()

    this.element.requestSubmit()
  }
}
