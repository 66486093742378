import { Controller } from '@hotwired/stimulus'
import { get } from '@rails/request.js'

export default class extends Controller {
  static targets = ['dependentSelect', 'parentSelect']
  static values = {
    queryKey: String,
    url: String,
  }

  declare dependentSelectTarget: HTMLSelectElement
  declare parentSelectTarget: HTMLSelectElement
  declare queryKeyValue: string
  declare urlValue: string

  change() {
    const searchParams = new URLSearchParams()
    const parentSelectValue = this.parentSelectTarget.value
    const targetId = this.dependentSelectTarget.id
    searchParams.append(this.queryKeyValue, parentSelectValue)
    searchParams.append('target', targetId)

    get(`${this.urlValue}?${searchParams}`, {
      responseKind: 'turbo-stream',
    })
  }
}
