import { Controller } from '@hotwired/stimulus'
import { useResize, useDebounce } from 'stimulus-use'

import type { PeaksInstance, PointOptions } from 'peaks.js'

const black = '#282524'
const pink = '#E76978'
const brownDark = '#68605A'
const brownLight = '#7E746E'
const paperDark = '#E4DDD0'
const pointColor = '#20b66e'

type Offset = { id: string; label: string }

// Connects to data-controller="master-recording-editor"
export default class MasterRecordingEditorController extends Controller<HTMLElement> {
  static debounces: string[] = ['resize']

  static targets: string[] = [
    'audioPlayer',
    'overview',
    'verseOffset',
    'zoomview',
  ]
  static values = {
    waveformUrl: String,
    points: Array,
  }

  declare readonly audioPlayerTarget: HTMLAudioElement
  declare readonly overviewTarget: HTMLDivElement
  declare readonly zoomviewTarget: HTMLDivElement
  declare readonly verseOffsetTargets: Array<HTMLElement>

  declare waveformUrlValue: string
  declare peaks?: PeaksInstance

  declare offsets: Offset[]

  async connect() {
    const peaksInit = await import('peaks.js').then((module) => {
      return module.default.init
    })

    if (!peaksInit) {
      throw new Error('Peaks.js not loaded')
    }

    useResize(this)
    useDebounce(this, { wait: 100 })

    this.offsets = this.verseOffsetTargets.reduce(
      (acc: Offset[], target: HTMLElement) => {
        const id = target.dataset.verseOffsetId
        const label = target.dataset.verseOffsetLabel

        if (id != null && label != null) {
          acc.push({ id, label })
        }

        return acc
      },
      []
    )

    const sharedColors = {
      playheadColor: black,
      axisLabelColor: brownLight,
    }

    peaksInit(
      {
        zoomLevels: [256, 512, 1024, 2048, 4096],
        zoomview: {
          ...sharedColors,
          container: this.zoomviewTarget,
          waveformColor: pink,
        },
        overview: {
          ...sharedColors,
          container: this.overviewTarget,
          waveformColor: paperDark,
          playheadTextColor: brownDark,
        },
        mediaElement: this.audioPlayerTarget,
        dataUri: {
          arraybuffer: this.waveformUrlValue,
        },
        points: this.verseOffsetTargets
          .map((element) => {
            return this.#pointForOffset(element)
          })
          .filter(Boolean) as PointOptions[],
      },
      (error, peaks) => {
        if (error) throw error
        if (!peaks) return

        this.peaks = peaks
        this.#addPointDragMoveHandler(peaks)
        this.#addPointDragEndHandler(peaks)
        this.#addPointDoubleClickHandler(peaks)
        this.#addPointRemovedHandler(peaks)
      }
    )
  }

  disconnect(): void {
    this.peaks?.destroy()
    this.peaks = undefined
  }

  resize() {
    this.peaks?.views.getView('zoomview')?.fitToContainer()
    this.peaks?.views.getView('overview')?.fitToContainer()
  }

  addMarker(event: Event): void {
    event.preventDefault()
    if (!this.peaks) return

    const totalMarkers = this.offsets.length
    const points = this.peaks.points.getPoints()

    if (points.length < totalMarkers) {
      const lastPoint = points[points.length - 1]
      const index = this.offsets.findIndex((offset) => {
        return offset.id == lastPoint.id
      })
      const offset = this.offsets[index + 1]

      this.peaks.points.add({
        id: offset.id,
        labelText: offset.label,
        time: this.peaks.player.getCurrentTime(),
        color: pointColor,
        editable: true,
      })
    } else {
      alert(
        `All ${totalMarkers} markers have been added. Please delete a marker before adding another.`
      )
    }

    this.#reorderPoints()
  }

  zoomIn(e: Event): void {
    e.preventDefault()
    this.peaks?.zoom.zoomIn()
  }

  zoomOut(e: Event): void {
    e.preventDefault()
    this.peaks?.zoom.zoomOut()
  }

  startOffsetChanged(event: Event): void {
    if (!this.peaks) return

    const input = event.target as HTMLInputElement
    const time = parseFloat(input.value)

    if (isNaN(time) || time < 0) {
      const id = input.dataset.verseOffsetUpdate

      if (id == null) throw 'ID not found'
      this.peaks.points.removeById(id)
    }

    this.#reorderPoints()
  }

  startOffsetInput(event: Event): void {
    if (!this.peaks) return

    const input = event.target as HTMLInputElement
    const id = input.dataset.verseOffsetUpdate

    if (id == null) throw 'ID not found'
    this.#updateInputs(id, input.value, true)

    const time = parseFloat(input.value)
    if (isNaN(time) || time < 0) return

    const point = this.peaks.points.getPoint(id)

    if (point) {
      point.update({ time })
    } else {
      const offset = this.offsets.find((offset) => {
        return offset.id == id
      })
      if (offset == null) throw 'Offset not found'

      this.peaks.points.add({
        id: offset.id,
        labelText: offset.label,
        time,
        color: pointColor,
        editable: true,
      })
    }
  }

  #addPointDragMoveHandler(peaks: PeaksInstance): void {
    peaks.on('points.dragmove', (event) => {
      if (event.point.id == null) throw 'Point has no ID'

      this.#updateInputs(event.point.id, event.point.time)
    })
  }

  #addPointDragEndHandler(peaks: PeaksInstance): void {
    peaks.on('points.dragend', () => {
      this.#reorderPoints()
    })
  }

  #addPointDoubleClickHandler(peaks: PeaksInstance): void {
    peaks.on('points.dblclick', (event) => {
      if (event.point.id == null) throw 'Point has no ID'

      peaks.points.removeById(event.point.id)
    })
  }

  #addPointRemovedHandler(peaks: PeaksInstance): void {
    peaks.on('points.remove', () => {
      this.#reorderPoints()
    })
  }

  #reorderPoints() {
    if (!this.peaks) return

    const sortedTimes = this.peaks.points
      .getPoints()
      .sort((a, b) => {
        if (a.time === b.time) {
          return 0
        } else if (a.time < b.time) {
          return -1
        } else {
          return 1
        }
      })
      .map((point) => point.time)

    this.peaks.points.removeAll()

    this.offsets.forEach((offset, index) => {
      const time = sortedTimes[index]

      if (time != null) {
        this.peaks?.points.add({
          id: offset.id,
          labelText: offset.label,
          time,
          editable: true,
          color: pointColor,
        })
      }

      this.#updateInputs(offset.id, time)
    })
  }

  #updateInputs(
    pointId: string,
    time: number | string | null,
    endOnly = false
  ) {
    const classSelector = endOnly ? '.audio-end-offset' : ''

    const elements: HTMLInputElement[] = Array.from(
      this.element.querySelectorAll(
        `[data-verse-offset-update="${pointId}"]${classSelector}`
      )
    )
    elements.forEach((element) => {
      element.value = (time ?? '').toString()

      // Dispatch a custom "pointchange" event that can be listened to without
      // triggering infinite loops on the "change" event.
      const event = new CustomEvent('pointchange')
      element.dispatchEvent(event)
    })
  }

  #pointForOffset(element: HTMLElement): PointOptions | undefined {
    const input: HTMLInputElement | null = element.querySelector(
      '[data-verse-offset-field="start"]'
    )
    const time = parseFloat(input?.value ?? '')

    if (!isNaN(time)) {
      return {
        time,
        id: element.dataset.verseOffsetId,
        labelText: element.dataset.verseOffsetLabel,
        color: pointColor,
        editable: true,
      }
    }
  }
}
