import { Controller } from '@hotwired/stimulus'
import { useThrottle, useDebounce } from 'stimulus-use'

// Connects to data-controller="carousel"
export default class extends Controller<HTMLElement> {
  static throttles: string[] = ['scrolling']
  static debounces: string[] = ['scrolled']

  static targets: string[] = ['slide', 'indicator']

  declare readonly slideTargets: Array<HTMLElement>
  declare readonly indicatorTargets: Array<HTMLElement>

  connect() {
    // Using both a Throttle and a Debounce
    // Throttle updates the dots while you scroll, but sometimes misses updating the final position
    // Debounce *only* updates the final position
    // Using these two together gives you dots that update frequently while scrolling, while also
    // ensuring that they end in the right spot.
    //
    // This is similar to Lodash's throttle with `leading: true`
    useThrottle(this, { wait: 50 })
    useDebounce(this, { wait: 50 })
  }

  showSlide(
    event: Event & { params: { index: number }; currentTarget: HTMLElement }
  ): void {
    event.preventDefault()

    const slideIndex = event.params.index
    const slide = this.slideTargets[slideIndex]
    const behavior = window.matchMedia('(prefers-reduced-motion: reduce)')
      .matches
      ? 'instant'
      : 'smooth'

    slide.scrollIntoView({ behavior, inline: 'center', block: 'nearest' })
  }

  scrolling(): void {
    this.#setCurrentIndicator()
  }

  scrolled(): void {
    this.#setCurrentIndicator()
  }

  #setCurrentIndicator() {
    const slideIndex = this.slideTargets.findIndex((target) => {
      return target.getBoundingClientRect().x >= 0
    })

    this.#setIndicator(slideIndex)
  }

  #setIndicator(slideIndex: number) {
    this.indicatorTargets.forEach((indicator, indicatorIndex) => {
      const checked = slideIndex === indicatorIndex
      indicator.setAttribute('aria-checked', checked.toString())
    })
  }
}
