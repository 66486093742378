import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="toast"
export default class extends Controller<HTMLDivElement> {
  static values = {
    dismissAfter: Number,
    showDelay: { type: Number, default: 200 },
    removeDelay: { type: Number, default: 800 },
  }

  static classes = ['show', 'hide']

  declare dismissAfterValue: number
  declare showDelayValue: number
  declare removeDelayValue: number
  declare hasDismissAfterValue: boolean

  declare hideClasses: string
  declare showClasses: string

  initialize() {
    this.hide()
  }

  connect() {
    setTimeout(() => {
      this.show()
    }, this.showDelayValue)

    // Auto dimiss if defined
    if (this.hasDismissAfterValue) {
      setTimeout(() => {
        this.close()
      }, this.dismissAfterValue + this.showDelayValue)
    }
  }

  hide(): void {
    this.element.classList.add(...this.hideClasses)
    this.element.classList.remove(...this.showClasses)
  }

  show(): void {
    this.element.classList.add(...this.showClasses)
    this.element.classList.remove(...this.hideClasses)
  }

  close() {
    this.hide()

    setTimeout(() => {
      this.element.remove()
    }, this.removeDelayValue)
  }
}
