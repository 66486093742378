import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { enter, leave } from 'el-transition'

// Connects to data-controller="modal"
export default class extends Controller<HTMLDivElement> {
  static targets: string[] = ['overlay', 'dialog']

  declare readonly overlayTarget: HTMLDivElement
  declare readonly dialogTarget: HTMLDivElement
  declare readonly closeButton: HTMLButtonElement

  connect() {
    useClickOutside(this, { element: this.dialogTarget })
  }

  clickOutside(event: Event) {
    event.preventDefault()
    this.close()
  }

  open() {
    enter(this.dialogTarget).then(() => {
      this.dialogTarget.removeAttribute('aria-hidden')
      this.dialogTarget.setAttribute('aria-modal', 'true')

      this.dialogTarget.focus()
    })

    enter(this.overlayTarget)
  }

  close() {
    leave(this.dialogTarget).then(() => {
      this.dialogTarget.setAttribute('aria-hidden', 'true')
      this.dialogTarget.removeAttribute('aria-modal')
    })
    leave(this.overlayTarget)
  }
}
