import { Controller } from '@hotwired/stimulus'
import { post, destroy } from '@rails/request.js'

// Connects to data-controller="toggle-switch"
export default class extends Controller<HTMLButtonElement> {
  static values = {
    url: String,
  }

  declare urlValue: string

  async submit(): Promise<void> {
    const isChecked = this.checked
    const func = isChecked ? destroy : post

    this.checked = !isChecked
    const response = await func(this.urlValue, {
      responseKind: 'turbo-stream',
    })

    if (response.ok) return

    this.checked = isChecked

    if (response.unprocessableEntity) return
    alert('An unexpected error has occurred. Please try again.')
  }

  get checked(): boolean {
    return this.element.getAttribute('aria-checked') === 'true'
  }

  set checked(value: boolean) {
    this.element.setAttribute('aria-checked', value.toString())
  }
}
