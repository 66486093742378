import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = [
    'container',
    'backdrop',
    'panel',
    'closeButton',
    'openButton',
  ]

  declare containerTarget: HTMLElement
  declare backdropTarget: HTMLElement
  declare panelTarget: HTMLElement
  declare closeButtonTarget: HTMLElement
  declare openButtonTarget: HTMLElement

  connect() {
    this.hide()
    useClickOutside(this, { element: this.panelTarget })
  }

  clickOutside() {
    this.hide()
  }

  show() {
    this.openButtonTarget.setAttribute('aria-hidden', 'true')
    this.containerTarget.classList.remove('hidden')
    this.containerTarget.setAttribute('aria-modal', 'true')
    this.closeButtonTarget.setAttribute('aria-hidden', 'false')
    document.body.style.position = 'fixed'
    enter(this.backdropTarget)
    enter(this.panelTarget)
  }

  hide() {
    document.body.style.position = ''
    Promise.all([leave(this.backdropTarget), leave(this.panelTarget)]).then(
      () => {
        this.containerTarget.classList.add('hidden')
        this.containerTarget.removeAttribute('aria-modal')
        this.closeButtonTarget.setAttribute('aria-hidden', 'true')
        this.openButtonTarget.setAttribute('aria-hidden', 'false')
      }
    )
  }

  toggle() {
    if (this.containerTarget.classList.contains('hidden')) {
      this.show()
    } else {
      this.hide()
    }
  }
}
