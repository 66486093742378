import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['item']
  static classes = ['hidden']

  declare hasHiddenClass: boolean
  declare hiddenClass: string
  declare itemTargets: HTMLElement[]
  declare class: string

  connect(): void {
    this.class = this.hasHiddenClass ? this.hiddenClass : 'hidden'

    this.itemTargets.forEach((item) => {
      this.#disableItem(item, item.classList.contains(this.class))
    })
  }

  toggle(): void {
    this.itemTargets.forEach((item) => {
      if (item.classList.contains(this.class)) {
        this.#showItem(item)
      } else {
        this.#hideItem(item)
      }
    })
  }

  show(): void {
    this.itemTargets.forEach((item) => this.#showItem(item))
  }

  hide(): void {
    this.itemTargets.forEach((item) => this.#hideItem(item))
  }

  #showItem(item: HTMLElement): void {
    item.classList.remove(this.class)
    this.#disableItem(item, false)
  }

  #disableItem(item: HTMLElement, disabled: boolean) {
    if ('disabled' in item) {
      item.disabled = disabled
    }
  }

  #hideItem(item: HTMLElement): void {
    item.classList.add(this.class)
    this.#disableItem(item, true)
    this.#pauseMedia(item)
  }

  #pauseMedia(element: HTMLElement) {
    const players = element.querySelectorAll(
      'audio, video'
    ) as NodeListOf<HTMLMediaElement>

    players.forEach((player) => {
      player.pause()
    })
  }
}
