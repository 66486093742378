import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="input-clear"
export default class extends Controller<HTMLElement> {
  static targets = ['input']

  declare readonly inputTarget: HTMLInputElement

  clear(event: Event): void {
    event.preventDefault()

    this.inputTarget.value = ''
  }
}
