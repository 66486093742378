import { Controller } from '@hotwired/stimulus'

const KEY = 'hidden-banner-messages'

// Connects to data-controller="banner-message"
export default class extends Controller<HTMLElement> {
  static values = {
    closable: { type: Boolean, default: true },
  }

  declare readonly closableValue: boolean

  initialize(): void {
    if (this.bannerIsHidden && this.closableValue) {
      this.#remove()
    }
  }

  hide(event: Event): void {
    event.preventDefault()

    if (!this.closableValue) return

    this.#markHidden()
    this.#remove()
  }

  get bannerIsHidden(): boolean {
    return this.hiddenBanners.includes(this.element.id)
  }

  get hiddenBanners(): Array<string> {
    const str = window.localStorage.getItem(KEY)

    if (str) {
      return JSON.parse(str)
    } else {
      return []
    }
  }

  #markHidden(): void {
    if (!this.bannerIsHidden) {
      const banners = this.hiddenBanners
      banners.push(this.element.id)

      window.localStorage.setItem(KEY, JSON.stringify(banners))
    }
  }

  #remove(): void {
    this.element.remove()
  }
}
