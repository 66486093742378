import { Controller } from '@hotwired/stimulus'
import { useClickOutside } from 'stimulus-use'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { enter, leave } from 'el-transition'

export default class extends Controller {
  static targets = ['menu']

  declare menuTarget: HTMLElement

  connect() {
    useClickOutside(this, { element: this.menuTarget })
  }

  show() {
    this.menuTarget.classList.remove('hidden')
    enter(this.menuTarget)
  }

  hide() {
    Promise.all([leave(this.menuTarget)]).then(() => {
      this.menuTarget.classList.add('hidden')
    })
  }

  toggle() {
    if (this.menuTarget.classList.contains('hidden')) {
      this.show()
    } else {
      this.hide()
    }
  }

  clickOutside() {
    this.hide()
  }
}
