import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="exclusive-options"
export default class extends Controller<HTMLElement> {
  static targets = ['primaryOption', 'secondaryOption']

  declare primaryOptionTargets: HTMLInputElement[]
  declare secondaryOptionTarget: HTMLInputElement

  deselectPrimaryOptions(): void {
    this.primaryOptionTargets.forEach((item) => {
      item.checked = false
    })
  }

  deselectSecondaryOption(): void {
    this.secondaryOptionTarget.value = ''
  }
}
